import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { Watch } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import UserService from "@/services/UserService";
import { IUser } from "@/models/IUser";
import { TheMask } from "vue-the-mask";
import { namespace } from "vuex-class";
import SiteService from "@/services/SiteService";
import { ISite } from "@/models/ISite";

const Auth = namespace("Auth");

@Options({
  components: {
    AdminLayout,
    Multiselect,
    TheMask,
  },
})
export default class UserModule extends Vue {
  siteKey = "xxxx2589";
  isloaded = false;
  loading = false;
  sites: any = [];
  totalItems = 0;
  current_page = 1;
  selectedBranch: any = {};
  selectedBrachPermission: any = [];
  value: any = [];
  search = "";
  active = false;
  message = "";
  inputValid = true;
  pageTitle = "";

  site: ISite = {
    siteId: 0,
    siteName: "",
    siteCms: "",
    siteKey: "",
    siteLocation: "",
    siteReturnUrl: "",
  };

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 15,
    totalElements: 0,
  };
  delCatId: number = 0;
  activePrompt: boolean = false;
  isFailed: boolean = false;
  failedMessage: string = "";

  async created() {
    (this as any).$Progress.start();
    var result = await SiteService.getListofSite(this.siteKey);

    if (result) {
      this.isloaded = false;
      this.sites = result;
      this.isloaded = true;
    }
    (this as any).$Progress.finish();
  }

  async setTable(pagenumber: number) {
    this.sites = [];
    (this as any).$Progress.start();
    var result = await SiteService.getListofSite(this.siteKey);

    if (result) {
      this.isloaded = false;
      this.sites = result;
      this.isloaded = true;
    }

    (this as any).$Progress.finish();
  }

  @Watch("sites")
  onPropertyChanged(value: string, oldValue: string) {
    this.isloaded = true;
  }

  @Watch("current_page")
  async onPropertyCurrentPageChanged(value: string, oldValue: string) {
    //this.isloaded = false
    await this.setTable(this.current_page);
    //console.log(this.current_page)
  }

  onBack() {
    this.active = !this.active;
  }

  async onOpen() {
    this.pageTitle = "Add New Site";
    this.active = !this.active;
    this.site = {
      siteId: 0,
      siteName: "",
      siteCms: "",
      siteKey: "",
      siteLocation: "",
      siteReturnUrl: "",
    };
  }

  openConfirm(catId: number) {

    this.delCatId = catId;
    this.activePrompt = true;
  }

  deleteCategory(userId: number) {
    (this as any).$Progress.start();
    console.log(userId);
    SiteService.deleteSite(this.delCatId, this.siteKey).then(
      (data) => {
        this.loading = false;
        if (data.statusCode == 200) {
          this.setTable(1);
          (this as any).$vs.notify({
            title: "Site",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          (this as any).$Progress.finish();
        } else {
          this.isFailed = true;
          this.failedMessage = data.message;
          (this as any).$vs.notify({
            title: "Site",
            text: data.message,
            color: "danger",
            icon: " highlight_off",
            time: 6000,
          });
          (this as any).$Progress.finish();
        }
        (this as any).$refs.observer.reset();
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "Site",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }



  async onEdit(siteId: number) {
    (this as any).$Progress.start();
    this.site = await SiteService.getSiteBySiteId(siteId, this.siteKey);

    this.pageTitle = "Edit New Site";
    this.active = !this.active;
    (this as any).$Progress.finish();
  }

  onSubmit() {
    (this as any).$Progress.start();
    SiteService.saveSite(this.site, this.siteKey).then(
      (data) => {
        if (data.statusCode == 200) {
          this.loading = false;
          this.setTable(1);
          (this as any).$vs.notify({
            title: "New Site",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          (this as any).$Progress.finish();
          if (this.site.siteId == 0) {
            this.site = {
              siteId: 0,
              siteName: "",
              siteCms: "",
              siteKey: "",
              siteLocation: "",
              siteReturnUrl: "",
            };
          }
        } else {
          this.loading = false;
          (this as any).$Progress.finish();
          this.message = data.message;
          this.inputValid = true;
        }
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New Site",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}
