import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import { IResponse } from "@/models/IResponse";
import { ISite } from "@/models/ISite";
import { IUser } from "@/models/IUser";
import axios from "axios";
import auhtHeader from "./auth-header";
import getBaseURL from "./Base";

let base = getBaseURL();

const API_URL = base.baseURL;
const userId = base.userId;
const clientId = base.clientId;

class SiteService {
  data: any;
  responsedata: any;

  saveSite(site: ISite, key: string): Promise<IResponse> {
    this.data = axios
      .post<IResponse>(API_URL + "account/saveSite", site, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.data;
  }

  deleteSite(siteId:number, key: string): Promise<IResponse> {
    this.responsedata = axios
      .post<IResponse>(API_URL + "account/deletesite", {id:siteId}, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getListofSite(key: string): Result {
    var URL =
      API_URL +
      "account/getallsites"

    this.responsedata = axios
      .get<IPageResponse>(URL, { headers: auhtHeader(key) })
      .then((response) => {
        const results = response.data.result;

        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getSiteBySiteId(
    siteId: number, key: string
  ): Promise<ISite> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL + "account/getsitebysiteid?siteId=" +
          siteId,
        { headers: auhtHeader(key) }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
}

export default new SiteService();
